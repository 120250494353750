<div *ngIf="!isPilot" class="content_main-footer-page mat-body-2">
  <div class="flex-col">
    <pbb-lang-toggle></pbb-lang-toggle>
  </div>

  <ul class="content_main-footer-page__list footer-link-list" *ngFor="let menuItem of footerLinks; let k = index">
    <li class="content_main-footer-page__list--menu-title text-grey-subtitle mb-16">
      <span id="footer-menu-label-{{ k }}">{{ menuItem.menuLabel | translate }}</span>
    </li>
    <li class="footer-links" *ngFor="let item of menuItem.options; let i = index">
      <a
        *ngIf="item.url"
        id="footer-link-{{ k }}-{{ i }}"
        target="_blank"
        class="link-text-footer"
        href="{{ item.url | translate }}"
        rel="noopener"
      >
        {{ item.optionLabel | translate }}
      </a>
    </li>
  </ul>
</div>
